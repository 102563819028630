<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div v-if="passwordSent == false" class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                </div>

                <vs-input
                  v-validate="'required|email'"
                  type="email"
                  data-vv-name="email"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full"
                />
                <div class="w-full mb-8">
                  <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }} </span>
                </div>

                <vs-button type="border" to="/pages/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                <vs-button
                  :disabled="errors.any() || !email"
                  v-on:click="forgotPassword()"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  >Recover Password</vs-button
                >
              </div>
            </div>
          </div>
          <div v-else class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>An email has been sent to {{ email }}, check your inbox and follow the instructions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  data() {
    return {
      email: '',
      passwordSent: false,
    }
  },
  methods: {
    isEmailValid() {
      return this.$validator.validate()
    },
    forgotPassword() {
      const params = {
        email: this.email,
        redirect_to: 'spaces',
      }
      this.$store
        .dispatch('auth/forgotPassword', params)
        .then(() => {
          //   this.$vs.loading.close()
          this.passwordSent = true
          this.$vs.notify({
            title: 'Success',
            text: 'Email sent successfully',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        })
        .catch(error => {
          //   this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
}
</script>
